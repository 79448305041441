import { useState } from "react";
import Modal from "./components/Modal";

export default function Home() {
  const [email, setEmail] = useState("");
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState("Error: Unknown Error");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/waitlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      setIsEmailModalOpen(true);
    } else {
      if (response.status === 400) {
        setErrorModalText("It looks like you're already on the waitlist! We'll send you an email when we're ready for you.");
        setIsErrorModalOpen(true);
      }

      if (response.status === 500) {
        setErrorModalText("It looks like something went wrong on our end. Please try again later.");
        setIsErrorModalOpen(true);
      }
    }
  };

  return (
    <>
      <main className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-b from-[#2e026d] to-[#15162c]">
        <div className="absolute top-4  flex w-fit justify-between px-4 py-6 text-[hsl(280,100%,70%)] sm:w-full sm:items-center sm:px-6 lg:px-8">
        <h1 className="text-3xl font-semibold">
          conduit<span className="text-[#FFD83A]">.</span>
        </h1>
        </div>
        <div className="container flex flex-col items-center justify-center gap-12 px-4 py-16 ">
          <h1 className="text-center text-5xl font-extrabold tracking-tight text-white sm:text-left sm:text-[5rem]">
            Infrastructure made{" "}
            <span className="text-[hsl(280,100%,70%)]">easy<span className="text-[#FFD83A]">.</span></span>
          </h1>
          <form onSubmit={onSubmit} className="grid grid-cols-10 gap-4">
            <div className="col-span-10 flex items-center sm:col-span-5 sm:col-start-2">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="w-full min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-span-10 sm:col-span-3 sm:col-end-10 sm:gap-6">
              <button
                type="submit"
                className="flex max-w-xs flex-col gap-4 rounded-xl bg-white/10 p-4 text-white hover:bg-white/20"
              >
                <h3 className="text-xl font-bold">Join the Waitlist →</h3>
              </button>
            </div>
          </form>
          <div className="text-lg font-medium text-white">
            <p>
              We are an AI-driven PaaS that builds your longstanding cloud infrastructure in a cost-effective manner.
              Sign up to get early access!
            </p>
          </div>
          <Modal
            isOpen={isEmailModalOpen}
            openModal={() => setIsEmailModalOpen(true)}
            closeModal={() => setIsEmailModalOpen(false)}
            content={{
              header: "You're all set!",
              body: "Keep an eye on your inbox for our invite. In the meantime, you can check out our site!",
              button: "Got it, thanks!",
            }}
          />
          <Modal
            isOpen={isErrorModalOpen}
            openModal={() => setIsErrorModalOpen(true)}
            closeModal={() => setIsErrorModalOpen(false)}
            content={{
              header: "Error:",
              body: errorModalText,
              button: "Okay",
            }}
          />
        </div>
      </main>


      
    </>
  );
}
